import { Col, Container, Row } from "react-bootstrap"

const CoomingSoon = () => {
    return(
        <div className="coming-soon-container">
            <Container>
                <div className="glitch" data-text="COOMING SOON">COMING SOON</div>
                <Row className="mt-5">
                    <Col md={6} className="mb-3">
                        <div className="title-logo">SIBERWANDER</div>
                        <div className="title">Guardians of Indonesia Digital Future</div>
                        <p className="mt-3">At Siberwander, we're on a mission to bring you the next generation of cybersecurity solutions. Our new site is on its way, and soon you'll have access to the latest in cloud security tools, managed services, and expert training.</p>

                        <div className="mt-5 box">
                            <h5>Stay Connected!</h5>
                            <p>We’ll be launching soon! In the meantime, reach out to us at <br/><strong><a href="https://wa.me/6285959541000">+62-859-5954-1000</a></strong> with any inquiries.</p>
                        </div>
                    </Col>
                    <Col md={6} className="mb-3">
                        <h5><strong>What You Can Expect:</strong></h5>
                        <ul className="mt-3">
                            <li><strong className="text-yellow">Cutting Edge Cloud Protection:</strong> SiberWander Protect your 100% cloud assets from evolving cyber threats with our comprehensive cloud security solutions in one platform : Cloud Security Posture Management, Cloud Workload Protection, Malware Scanning, Sensitive Data Scanning, Vulnerability Management, Shift Left Security, API Security, Container Security, Cloud Data Security, Cloud Detection and Response, Kubernetes Security Posture Management, Cloud Compliance and Governance.</li>
                            <li><strong className="text-yellow">Managed Cloud Security Services:</strong> Our managed cloud security services ensure that your cloud environment is continuously monitored, vulnerabilities are promptly addressed, and your compliance requirements are met, so you can focus on growing your business.</li>
                            <li><strong className="text-yellow">Cybersecurity Training:</strong> Our tailored training programs empower your team with the skills they need to recognize and respond to cybersecurity threats, ensuring your organization remains resilient.</li>
                        </ul>
                    </Col>
                </Row>
                <div className="text-right">
                    <h5 className="mt-2"><strong>Thank you for your interest and patience!</strong></h5>
                    <p>Siberwander – Defending Your Digital Frontier</p>
                </div>
            </Container>
        </div>
    )
}

export default CoomingSoon