import './App.css';
import CoomingSoon from './pages/ComingSoon';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <CoomingSoon/>
  );
}

export default App;
